<template>
  <div class="customFooterModalBodyEmail overflow-x-hidden">
    <div class="row">
      <div class="col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">CC</span>
        <input class="form-control" type="text" v-model="emailData.ccEmails" id="email-modal-additional-contact-tab-cc" />
      </div>
      <div class="col-md-6 u-mb-xsmall">
        <span class="c-toolbar__state-title u-text-capitalize">BCC</span>
        <input class="form-control" type="text" v-model="emailData.bccEmails" id="email-modal-additional-contact-tab-bcc" />
      </div>
      <div class="col-md-12 mt-3" v-if="$isDev">
        <h5>Add Additonal Contacts</h5>

        <table class="table" id="email-modal-additional-contact-table">
          <thead class="c-table__head c-table__head--slim">
            <tr class="c-table__row">
              <th class="c-table__cell c-table__cell--head no-sort w-30px vertical-middle">Cc</th>
              <th class="c-table__cell c-table__cell--head no-sort w-30px vertical-middle">Bcc</th>
              <th class="c-table__cell c-table__cell--head no-sort  w-200px vertical-middle">
                <input class="form-control w-200px" type="text" placeholder="Search Contact" v-model="q" id="email-modal-additional-contact-tab-search" />
              </th>
              <th class="c-table__cell c-table__cell--head no-sort w-200px vertical-middle">Email</th>
              <th class="c-table__cell c-table__cell--head no-sort w-200px vertical-middle">Title</th>
              <th class="c-table__cell c-table__cell--head no-sort w-200px vertical-middle">Phone</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="n in contactsList" :key="n.id">
              <td class="align-middle">
                <div v-if="n.email" class=" c-choice c-choice--checkbox mb-0">
                  <input
                    class="c-choice__input mb-0 email-modal-additional-contact-tab-cc"
                    :id="`${n.id}-additional-contact-cc`"
                    v-model="selectedCC"
                    :value="n.email"
                    type="checkbox"
                    @change="checkInBCC(n.email)"
                  />
                  <label :for="`${n.id}-additional-contact-cc`" class="c-choice__label mb-0 email-modal-additional-contact-tab-cc_label"></label>
                </div>
              </td>
              <td class="align-middle">
                <div v-if="n.email" class=" c-choice c-choice--checkbox mb-0">
                  <input
                    class="c-choice__input mb-0 email-modal-additional-contact-tab-bcc"
                    :id="`${n.id}-additional-contact-cc-bcc`"
                    v-model="selectedBCC"
                    :value="n.email"
                    type="checkbox"
                    @change="checkInCC(n.email)"
                  />
                  <label :for="`${n.id}-additional-contact-cc-bcc`" class="c-choice__label mb-0 email-modal-additional-contact-tab-label"></label>
                </div>
              </td>
              <td class="email-modal-additional-contact-tab-name" id="additional-contact-name">{{ n.name }}</td>
              <td class="email-modal-additional-contact-tab-email" id="additional-contact-email">{{ n.email }}</td>
              <td class="email-modal-additional-contact-tab-role" id="additional-contact-role">{{ n.role }}</td>
              <td colspan="2" class="email-modal-additional-contact-tab-phone" id="additional-contact-phone">{{ n.phone ? formatPhoneNumber(n.phone) : "-" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  import UtilityString from "@/utilities/strings";
  import {debounce} from "lodash/function";
  import {intersection} from "lodash/array";
  const global = new GlobalServices();
  const utility = new UtilityString();
  export default {
    data() {
      return {
        // selectedCC: [],
        // selectedBCC: [],
        contactsList: [],
        q: null,
      };
    },
    computed: {
      emailData: {
        get() {
          return this.$store.getters.getEmailData;
        },
        set(value) {
          this.$store.commit("setEmailData", value);
        },
      },
      selectedCC: {
        get() {
          return this.$store.getters.getCcData;
        },
        set(value) {
          this.$store.commit("setEmailCcData", value);
        },
      },
      selectedBCC: {
        get() {
          return this.$store.getters.getBccData;
        },
        set(value) {
          this.$store.commit("setEmailBccData", value);
        },
      },
    },
    watch: {
      q() {
        this.debounceSearch();
      },
    },
    methods: {
      debounceSearch: debounce(function() {
        this.init();
      }, 1000),
      async init() {
        const clientId = this.emailData.clientId;
        const payload = {
          id: clientId,
          parentActionId: MODULE.CLIENTS.ActionId,
          actionId: MODULE.CONTACTS.ActionId,
        };

        if (this.q) payload.q = this.q;
        this.contactsList = await global.getTabTableleDataByActionId(payload);
        setTimeout(this.setCCandBCC, 500);
      },
      setCCandBCC() {
        const automatedCC = this.emailData.ccEmails
          ? this.emailData.ccEmails
              .split(",")
              .filter((d) => d !== "")
              .map((d) => d.trim())
          : [];
        const automatedBCC = this.emailData.bccEmails
          ? this.emailData.bccEmails
              .split(",")
              .filter((d) => d !== "")
              .map((d) => d.trim())
          : [];
        const tempCC = [];
        const tempBCC = [];
        this.contactsList.forEach((d) => {
          if (intersection([d.email], automatedCC).length > 0) {
            tempCC.push(d.email);
          }
          if (intersection([d.email], automatedBCC).length > 0) {
            tempBCC.push(d.email);
          }
        });
        this.$store.commit("setEmailCcData", tempCC);
        this.$store.commit("setEmailBccData", tempBCC);
      },
      formatPhoneNumber(phone) {
        return utility.formatPhoneNumber(phone);
      },
      checkInBCC(email) {
        const index = this.selectedBCC.findIndex((d) => d === email);
        if (index >= 0) {
          this.selectedBCC.splice(index, 1);
        }
      },
      checkInCC(email) {
        const index = this.selectedCC.findIndex((d) => d === email);
        if (index >= 0) {
          this.selectedCC.splice(index, 1);
        }
      },
    },
    mounted() {
      this.init();
    },
  };
</script>
